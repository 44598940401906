import * as React from "react";
import PageBody from "../components/PageBody";
import Paragraph from "../components/Paragraph";
import { defaultFields as leadFormFields } from "../config/ContactForm.data";

import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function AboutPage() {
  const seo = createSeoData({
    title: "About Lingua Fonica",
    description: "Learn about Lingua Fonica and our services",
  });

  const hero = {
    heroTitleLine1: "About",
    heroTitleLine2: "Lingua Fonica",
    heroContent: "The better way to learn a language",
  };

  return (
    <Layout seo={seo} hero={hero} leadFormFields={leadFormFields}>
      <PageBody limitMaxWidth>
        <Paragraph>
          Lingua Fonica boasts expert instructors. Our instructors are fluent in
          the languages they teach and have the right combination of education
          and experience to make them effective.
        </Paragraph>
        <Paragraph>
          Whether you are a student, tourist, or business professional, with
          extensive or no experience with your target language, we will work
          with you to meet your individual language goals.
        </Paragraph>
        <Paragraph>
          Get a one-on-one private tutoring experience from the comfort of your
          own home or other space. Do you want early morning classes, late
          night, middle of the day? We will work around your schedule.
        </Paragraph>
      </PageBody>
    </Layout>
  );
}
